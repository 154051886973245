const defaultStats = [
  { id: 1, name: 'News Sources', value: '10,000+' },
  { id: 2, name: 'Companies Covered', value: '2M+' },
  { id: 3, name: 'Reports & Filings', value: 'Millions' },
  { id: 4, name: 'Uptime', value: '99.99%' }
];

type statsProps = {
  stats?: {
    id: number;
    name: string;
    value: string;
  }[];
};

export default function Stats({ stats = defaultStats }: statsProps) {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          {/* <div className="text-center">
            <h2 className="text-3xl tracking-tight text-primary sm:text-4xl">
              Comprehensive Coverage
            </h2>
            <p className="mt-4  leading-8 opacity-80 mx-auto">
              We support a global universe of news, stocks and alternative data.
            </p>
          </div> */}
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className="flex flex-col bg-black/5 dark:bg-black/80 p-8"
              >
                <dt className="text-sm font-semibold leading-6 opacity-60">
                  {stat.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight ">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

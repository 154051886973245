import { Check, ChevronRight } from 'lucide-react';
import cn from 'classnames';
import { useState } from 'react';
import { useNotification } from '@/hooks/useNotification';
import { animated, useSpring } from 'react-spring';

type Props = {
  // title: string;
  // href: string;
  style?: 'primary' | 'secondary';
  arrow?: boolean;
  small?: boolean;
  message?: string;
};

export default function LandingButton({
  style = 'primary',
  arrow = true,
  small = false,
  message = 'Get in touch homepage'
}: Props) {
  const notification = useNotification();
  const [activated, setActivated] = useState(false);
  const [email, setEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { x } = useSpring({
    from: { x: 0 },
    x: submitted ? 1 : 0,
    config: { duration: 500 }
  });
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const data = {
      first_name: '',
      last_name: '',
      message: message,
      email: email,
      phone_number: '',
      company: ''
    };
    fetch(`/api/contact/submit`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 'error') {
          notification.showError({
            title: 'Error submitting form',
            message: res.message
          });
          return;
        }
        setSubmitted(true);
      })
      .catch((error) => {
        notification.showError({
          title: 'Error submitting form',
          message: error.message
        });
      });
  };
  if (submitted) {
    return (
      <animated.div
        className={cn(
          '   flex flex-row border-green-500 border-2 rounded-full overflow-clip  w-auto'
        )}
        // className={styles.text}
        style={{
          // opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
          scale: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [2, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
          })
        }}
      >
        <div className="bg-green-500  w-full text-white px-5 py-2 flex items-center justify-center gap-x-1 whitespace-pre ">
          <Check /> We'll be in touch soon!
        </div>
      </animated.div>
    );
  }

  return (
    <div
      className={cn(
        '   flex flex-row border- border-2 rounded-full overflow-clip  transition-all ',
        {
          'w-auto': !activated,
          'max-w-lg w-full': activated
        }
      )}
    >
      <input
        className={cn(
          'bg-white border-none transition-all outline-none  px-0 text-black',
          {
            'w-0 ': !activated,
            'w-full pl-5': activated
          }
        )}
        type="text"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        // onFocus={() => setActivated(true)}
        // onBlur={() => setActivated(false)}
      />
      <button
        className={cn(
          'bg-neutral-100 dark:hover:bg-neutral-300 transition-all text-black  px-5 py-2 flex items-center justify-center gap-x-2 whitespace-pre hover:bg-neutral-200',
          {
            'w-full': !activated,
            'w-auto': activated
          }
        )}
        onClick={(e: any) => {
          console.log('clicked');
          if (!activated) setActivated(true);
          if (activated) handleSubmit(e);
        }}
      >
        {activated ? (
          <>
            <ChevronRight size={15} strokeWidth={2} />
          </>
        ) : (
          <>
            Get In Touch
            <ChevronRight size={15} strokeWidth={2} />
          </>
        )}
      </button>
    </div>
  );
}

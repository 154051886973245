import LandingEmail from '../../Button/LandingEmail';

type Props = {
  topText?: string;
  bottomText?: string;
};

export default function FooterCta({
  topText = 'The Future of Research',
  bottomText = 'Available Today'
}: Props) {
  return (
    <div className="content-default flex flex-col justify-center pb-0">
      <h2 className="text-center text-5xl font-medium tracking-tighter leading-tight text-transparent bg-clip-text bg-gradient-to-b from-neutral-600 to-neutral-800 dark:from-neutral-200 dark:to-neutral-400">
        {topText}
        <br />
        {bottomText}
      </h2>
      <div className="flex flex-row gap-x-4 py-10 justify-center">
        <LandingEmail />
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const Globe = dynamic(() => import('react-globe.gl'), {
  loading: () => <p>Loading...</p>, // Optional: Add a loading component or message
  ssr: false // Set to false to disable server-side rendering for this component
});
const N = 20;
const arcsData = [...Array(N).keys()].map(() => ({
  startLat: (Math.random() - 0.5) * 180,
  startLng: (Math.random() - 0.5) * 360,
  endLat: (Math.random() - 0.5) * 180,
  endLng: (Math.random() - 0.5) * 360,
  color: [
    ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)],
    ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
  ]
}));

export default function GlobeComponent({
  width,
  height
}: {
  width: number;
  height: number;
}) {
  const [countries, setCountries] = useState({ features: [] });
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;
  const splineColors = ['00FFA3', '00D930', '00A1FB', '6100FF'];
  const colors = ['BABABA'];
  useEffect(() => {
    // load data
    fetch('/data/ne_110m_admin_0_countries.geojson')
      .then((res) => res.json())
      .then(setCountries);
  }, []);

  if (!countries.features.length) return null;
  console.log('width', width);
  return (
    <Globe
      width={width}
      height={height}
      backgroundColor="rgba(0,0,0,0)"
      //   globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"
      hexPolygonsData={countries.features}
      hexPolygonResolution={3}
      hexPolygonMargin={0.6}
      showGlobe={false}
      //   hexPolygonAltitude={(d) => Math.sqrt(d?.POP_EST || 5) * 2}
      hexPolygonUseDots={true}
      showAtmosphere={false}
      // atmosphereColor="rgba(0, 255, 163, 0.0)"
      //   atmosphereColor={(d) => {
      //
      //     return '#000000';
      //   }}
      hexPolygonColor={() =>
        `#${colors[Math.floor(Math.random() * colors.length)]}`
      }
      arcsData={arcsData}
      arcColor={() =>
        `#${splineColors[Math.floor(Math.random() * splineColors.length)]}`
      }
      arcDashGap={() => Math.random() * 3 + 1}
      arcAltitudeAutoScale={0.5}
      arcDashAnimateTime={() => Math.random() * 4000 + 500}
      //    .arcsData(arcsData)
      // .arcColor('color')
      // .arcDashLength(() => Math.random())
      // .arcDashGap(() => Math.random())
      // .arcDashAnimateTime(() => Math.random() * 4000 + 500)
      // hexPolygonColor={() =>
      //   `#${Math.round(Math.random() * Math.pow(2, 24))
      //     .toString(16)
      //     .padStart(6, '0')}`
      // }
    />
  );
}

import Footer from '@/components/ui/Footer';
import Head from 'next/head';
import { useNotification } from '@/hooks/useNotification';
import { PageMeta } from '@/types/stripe/stripe';
import { Fragment, ReactNode } from 'react';
import Header from './ui/PageBlocks/Marketing/Header';
import { useUser } from '@/utils/useUser';

interface Props {
  children: ReactNode;
  meta?: PageMeta;
}

export function LayoutNoSidebar({ children, meta: pageMeta }: Props) {
  const meta = {
    title: 'markets.sh',
    description: '',
    ...pageMeta
  };
  const { user } = useUser();
  const notification = useNotification();

  return (
    <Fragment>
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
      </Head>
      <div className="min-h-screen ">
        <Header />
        {notification.component}

        <div className="fixed top-0 left-0 bg-gradient-to-b  from-neutral-100 to-white dark:from-[#0c0c0c] dark:to-main-background min-h-screen w-screen"></div>
        <div className="flex flex-col flex-1 z-10">
          <main className="flex-1">
            <div id="skip" className="relative  ">
              {children}
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

LayoutNoSidebar.getLayout = false;

export default LayoutNoSidebar;

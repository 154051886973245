type Feature = {
  name: string;
  icon: React.FC<any>;
};
type FeatureBlockProps = {
  features: Feature[];
};

//  <div className="flex flex-row space-x-2 items-center justify-center pt-10">
//    {features.map((f, i) => (
//      <div className=" p-2 gap-y-2 flex flex-col" key={`feat-${i}`}>
//        <f.icon className="stroke-white" strokeWidth={1} />
//        <h3 className="font-medium  ">{f.name}</h3>
//      </div>
//    ))}
//  </div>;
export default function FeatureBlock({ features }: FeatureBlockProps) {
  return (
    <div className="  ">
      <div className="mx-auto">
        <div className="mx-auto max-w-2xl lg:max-w-none flex items-center mb-8 overflow-x-auto scrollbar-none">
          {/* <div className="text-center">
            <h2 className="text-3xl tracking-tight text-primary sm:text-4xl">
              Comprehensive Coverage
            </h2>
            <p className="mt-4  leading-8 opacity-80 mx-auto">
              We support a global universe of news, stocks and alternative data.
            </p>
          </div> */}
          <dl className="flex gap-x-1 rounded-lg overflow-clip ">
            {features.map((f, i) => (
              <div
                key={`feat-${i}`}
                className="flex flex-col pt-2 pb-1 gap-y-1 px-5 bg-neutral-100 dark:bg-black min-w-[8rem] items-center "
              >
                <dt className="text-xs   font-semibold leading-6 opacity-80 text-primary whitespace-pre">
                  {f.name}
                </dt>
                <dd className="order-first text-3xl font-semibold tracking-tight flex justify-center">
                  <f.icon
                    className="stroke-black dark:stroke-white  "
                    strokeWidth={1.5}
                    size={20}
                  />
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

import { ChevronRight } from 'lucide-react';
import Link from 'next/link';

export const TopHighlight = () => {
  return (
    <div
      className="left-1/2 top-0 w-[150px] user-select-none center pointer-events-none absolute h-px max-w-full -translate-x-1/2 -translate-y-1/2 z-20 "
      style={{
        background:
          'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.0) 0%, rgba(143, 143, 143, 0.67) 50%, rgba(0, 0, 0, 0) 100%)'
      }}
    ></div>
  );
};
export const MainLightHighlight = () => {
  return (
    <>
      <div
        aria-hidden="true"
        className="-top-1 dark:hidden right-0 h-[100vh] w-full max-w-[60%] user-select-none center pointer-events-none absolute  -translate-y-1/2  z-20"
        style={{
          background: `conic-gradient(from 90deg at 50% 50%, #00000000 50%, #000 50%), radial-gradient(rgba(100, 100, 100, 0.1) 0%, transparent 80%)`
        }}
      ></div>
      <div
        aria-hidden="true"
        className="-top-1 hidden dark:block right-0 h-[100vh] w-full max-w-[60%] user-select-none center pointer-events-none absolute  -translate-y-1/2  z-20"
        style={{
          background: `conic-gradient(from 90deg at 50% 50%, #00000000 50%, #000 50%), radial-gradient(rgba(150, 150, 150, 0.15) 0%, transparent 80%)`
        }}
      ></div>
    </>
  );
};

export const TopGradientHighlight = () => {
  return (
    <div
      aria-hidden="true"
      className="-top-1 left-1/2 h-[200px] w-full max-w-[200px] md:max-w-[400px] user-select-none center pointer-events-none absolute max-w-full -translate-x-1/2 -translate-y-1/2"
      style={{
        background: `conic-gradient(from 90deg at 50% 50%, #00000000 50%, #000 50%), radial-gradient(rgba(200, 200, 200, 0.1) 0%, transparent 80%)`
      }}
    ></div>
  );
};

export const CTALink = ({
  href,
  text = 'Learn more',
  className = 'flex flex-row text-blue-500'
}) => {
  return (
    <Link href={href} className={className}>
      {text}
      <ChevronRight />
    </Link>
  );
};
